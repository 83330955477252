import React from "react"
import { Link, navigate } from "gatsby"

import RrLogo from "../assets/img/rapyuta_robotics_logo.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Progress from "../components/progress"
import {
  ajax,
  API_CALL_STATUSES,
  getQueryParam,
  getRedirectPathFromUrl,
} from "../utils"
import CenteredContainer from "../components/CenteredContainer"
import FancyLabel from "../components/FancyLabel"
import FancyInput, { FancyCardHeader } from "../components/FancyInput"

const getErrorMessage = err => {
  switch (err && err.status ? err.status : "") {
    case 500:
      return "Password reset failed due to server error. Please try again later."
    default:
      return "Failed to reset password"
  }
}

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newPassword: "",
      confirmPassword: "",
      token: getQueryParam("t") || "",
      resetStatus: API_CALL_STATUSES.IDLE,
      resetError: "",
      timer: 5,
    }
  }

  validateInputItem = inputItemName => {
    const { newPassword, confirmPassword } = this.state
    let regex = /^[A-Za-z]+$/
    switch (inputItemName) {
      case "newPassword":
        regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[$@!*\_\-#%\^&])[A-Za-z\d$@!*\_\-#%\^&]{8,}$/
        return !newPassword || !!newPassword.match(regex)
      case "confirmPassword":
        return !confirmPassword || newPassword === confirmPassword
    }
  }

  validateForm = () => {
    let isValidForm = true
    ;["newPassword", "confirmPassword"].forEach(itemName => {
      const validationResult = this.validateInputItem(itemName)
      if (!validationResult) {
        isValidForm = false
      }
    })
    return isValidForm
  }

  onResetSubmit = e => {
    e.preventDefault()
    const { newPassword, token } = this.state

    this.setState({
      resetStatus: API_CALL_STATUSES.PROGRESS,
    })

    ajax({
      path: "user/resetpassword",
      type: "PUT",
      data: {
        token,
        newPassword: newPassword,
      },
      success: () => {
        this.setState(
          {
            resetStatus: API_CALL_STATUSES.SUCCESS,
          },
          () => {
            this.setState({
              timer: 5,
            })
            setInterval(() => {
              const { timer } = this.state
              if (timer <= 1) {
                navigate(`/login/${getRedirectPathFromUrl()}`)
              } else {
                this.setState({
                  timer: timer - 1,
                })
              }
            }, 1000)
          }
        )
      },
      error: err => {
        this.setState({
          resetStatus: API_CALL_STATUSES.ERROR,
          resetError: getErrorMessage(err),
        })
      },
    })
  }

  updateValue = e => {
    const {
      target: {
        dataset: { id },
        value,
      },
    } = e
    e.preventDefault()
    this.setState({
      [id]: value,
    })
  }

  render() {
    const {
      newPassword,
      confirmPassword,
      token,
      resetStatus,
      resetError,
      timer,
    } = this.state

    if (!token) {
      return (
        <Layout dark bgimage stickyFooter>
          <SEO title="Reset Password" />
          <CenteredContainer>
                <div id="noToken">
                  <h3>UNAUTHORIZED</h3>
                  <p className="subText">
                    You are not allowed to view this page.
                    <br />
                    Go to{" "}
                    <Link to={`/login/${getRedirectPathFromUrl()}`}>
                      Login page
                    </Link>
                  </p>
                </div>
          </CenteredContainer>
        </Layout>
      )
    }

    return (
      <Layout>
        <SEO title="Reset Password" />
        {resetStatus === API_CALL_STATUSES.PROGRESS && <Progress />}
        <CenteredContainer>
              {resetStatus === API_CALL_STATUSES.SUCCESS ? (
                <div id="success">
                  <FancyCardHeader title="Reset Password"></FancyCardHeader>
                  <p className="subText">
                    Successfully reset password
                    <br />
                    Redirecting to{" "}
                    <Link to={`/login/${getRedirectPathFromUrl()}`}>
                      Login page
                    </Link>{" "}
                    in <span>{timer}</span> sec
                  </p>
                </div>
              ) : (
                <form noValidate onSubmit={this.onResetSubmit}>
                  <FancyCardHeader title="Reset Password"></FancyCardHeader>
                  <div className="inputWrapper">
                    <FancyLabel>Password</FancyLabel>
                    <FancyInput
                      type="password"
                      data-id="newPassword"
                      onChange={this.updateValue}
                      value={newPassword}
                      required
                    />
                    {!this.validateInputItem("newPassword") && (
                      <span className="validation">
                        Password must be a minimum of 8 characters long with at
                        least: one uppercase letter, one lowercase letter, one
                        number, and one character among _-!@#$%^&*
                      </span>
                    )}
                  </div>
                  <div className="inputWrapper">
                    <FancyLabel>Confirm Password</FancyLabel>
                    <FancyInput
                      type="password"
                      data-id="confirmPassword"
                      onChange={this.updateValue}
                      value={confirmPassword}
                      required
                    />
                    {!this.validateInputItem("confirmPassword") && (
                      <span className="validation">Passwords do not match</span>
                    )}
                  </div>
                  {resetStatus === API_CALL_STATUSES.ERROR && (
                    <p id="error">{resetError}</p>
                  )}
                  <button disabled={!this.validateForm()} className="redBtn">
                    CONTINUE
                  </button>
                </form>
              )}
        </CenteredContainer>
      </Layout>
    )
  }
}

export default ResetPassword
